// store/index.js
import {createStore} from 'vuex'
import user from './modules/user'
import getters from './getters'
// import { genStore } from '@/utils/batchImportFiles'

export default createStore({
   modules:{
    user,
   //  ...allStores
   },
   getters
})
