import request from '@/utils/request'

/**-----------------------------医院------------------------------------------ */

export const searchOrderList: any = (data: any) => {
    return request.post('/patient/case/order/list', data)
}
// 创建医院
export const createClinic: any = (data: any) => {
    return request({
        url: '/clinic',
        method: 'post',
        data
    })
}

// 查询所有医院
export const searchClinic: any = (data: any) => {
    return request({
        url: '/clinic/search',
        method: 'post',
        data
    })
}
// 根据id查询医院
export const getClinicInfo: any = (id: any) => {
    return request({
        url: `/clinic/${id}`,
        method: 'get',
    })
}


/**
 * 根据牙医Id获取所在的诊所列表.
 * @param dentistId
 */

export const getClinics: any = (dentistId: any) => {
    return request({
        url: `/clinic/list/${dentistId}`,
        method: 'get',
    })
}


// 查询所有医院
export const updateClinic: any = (data: any) => {
    return request({
        url: '/clinic',
        method: 'put',
        data
    })
}

// 列表查询 充值次数
export const pageListRecharge: any = (data: any) => {
    return request({
        url: '/clinic/pageListRecharge',
        method: 'post',
        data
    })
}

// 保存 充值次数
export const saveRecharge: any = (data: any) => {
    return request({
        url: '/clinic/saveRecharge',
        method: 'post',
        data
    })
}
export const getUsedRechargeFrequencyWithClinicId: any = (clinicId: string) => {
    return request({
        url: `/clinic/getUsedRechargeFrequencyWithClinicId/${clinicId}`,
        method: 'get',
    })
}

export const getUsedRechargeFrequencyWithUserId: any = (userId: string) => {
    return request({
        url: `/clinic/getUsedRechargeFrequencyWithUserId/${userId}`,
        method: 'get',
    })
}