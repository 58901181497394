import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import config from '@/config/defaultSettings';
import Patient from '@/views/patient/Patient.vue'
import Home from '@/views/Home.vue'
import commonRoutes from './commonRoutes'
import { setupBeforeEach, setupAfterEach } from './routerGuard'

const routes: Array<RouteRecordRaw> = commonRoutes as unknown as RouteRecordRaw[]
// 菜单主路由
export const menuRoutes = routes.find((r) => r.name === "index");
// 当前路由
export const currentRoute = menuRoutes?.children?.find((r) => r.path === window.location.pathname);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 路由守卫,鉴权
setupBeforeEach(router)

setupAfterEach(router)

export default router
