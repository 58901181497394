/* eslint-disable @typescript-eslint/no-explicit-any */
// 获取当前地区的所有code
let nodes: any[] = [];
export function getParentNodes(id: number | string, tree: any[],key:string): any[] {
  console.log('id', id);
  _getParentNodes([], id, tree,key);
  return nodes;
}

function _getParentNodes(his: any[], targetId: any, tree: any[],key:string) {
  tree.some((list) => {
    const children = list.children || [];
    if (list.id == targetId) {
      console.log('最后', list);
      his.push(list[key])
      nodes = his;
      return true;
    } else if (children.length > 0) {
      // console.log('遍历树', tree);
      const history = [...his];
      history.push(list[key]);
      return _getParentNodes(history, targetId, children,key);
    }
  })
}

/**
 * 格式化时间戳 年月日 时分
 * @param str 
 * @returns 2023-09-01 08:00
 */
export function formatDateTime(str: string): string {
  if (str == "" || !str) {
    return "";
  }
  const date = new Date(str);
  const y = date.getFullYear();
  let m: string | number = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  let d: string | number = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  let h: string | number = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  let minute: string | number = date.getMinutes();
  minute = minute < 10 ? ('0' + minute) : minute;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
}

/**
 * 格式化时间戳 年月日 时分秒
 * @param str 
 * @returns 2023-09-01 08:00:00
 */

export function formatDateTime2(str: string): string {
  if (str == "" || !str) {
    return "";
  }
  const date = new Date(str);
  const y = date.getFullYear();
  let m: string | number = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  let d: string | number = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  let h: string | number = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  let minute: string | number = date.getMinutes();
  minute = minute < 10 ? ('0' + minute) : minute;
  let second: string | number = date.getSeconds();
  second = second < 10 ? ('0' + second) : second;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
}

// 格式化日期
export function getDate(str: string): string {
  if (null === str) return '';
  const now = new Date(str);
  const year = now.getFullYear(); //得到年份
  let month: string | number = now.getMonth(); //得到月份
  let date: string | number = now.getDate(); //得到日期
  month = month + 1;
  if (month < 10) month = "0" + month;
  if (date < 10) date = "0" + date;
  const time = year + "-" + month + "-" + date; //（格式化"yyyy-MM-dd"）
  return time
}

// 根据出生日期返回年龄 2023-2-21
export function age_Conversion(date: string): string | boolean | number {
  let age = 0;
  const str = date.replace(/年|月/g, "-").replace(/日/g, "");
  const r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
  if (r == null) return false;
  const d = new Date(+r[1], +r[3] - 1, +r[4]);
  if (d.getFullYear() == +r[1] && (d.getMonth() + 1) == +r[3] && d.getDate() == +r[4]) {
    const Y = new Date().getFullYear();
    age = (Y - +r[1]);
    return age;
  } else {
    return '时间格式错误';
  }
}

/**
 * 获取uuid
 */
export function getUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : (+'r&0x3' | +'0x8')).toString(16)
  })
}

// 删除数组里面的空children
export function deleteChildren(arr: any[]): any[] {
  const list = arr;
  for (let i = list.length; i--; i > 0) {
    if (list[i].children) {
      if (list[i].children.length) {
        deleteChildren(list[i].children);
      } else {
        delete list[i].children;
      }
    }
  }
  return arr;
}


export function deleteHasInList(currList: any[], otherList: any[]) {
  let i = currList.length
  while (i--) {
    if (otherList.some((x) => {
      return currList[i].value === x.value
    })) currList.splice(i, 1)
  }
}