<!-- eslint-disable vue/valid-v-for -->
<template>
  <a-layout-sider :collapsed="collapsed" :trigger="null" collapsible>
    <div class="logo" v-if="!collapsed">
      马上看牙
    </div>
    <div v-else class="logo">银马</div>
    <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
      <template v-for="item in orginRoutes">
        <a-menu-item v-if="!item.children && !item.meta.hidden" :key="item.path">
          <router-link :to="getPath(item)">
            <span v-if="item.meta.icon" class="anticon">
              <component :is="$antIcons[item.meta.icon]" />
            </span>
            <span>{{ item.meta.title }}</span>
          </router-link>
        </a-menu-item>
        <sub-menu v-else-if="item.children" :menu-info="item" :key="item.path"
          :collapsed="collapsed" />
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { menuRoutes } from '@/router/index'
import { ref, watch, defineProps } from "vue"
import SubMenu from './SubMenu.vue'
import { hasPermission,filteRouterPermission } from '@/router/permission'
import { LocalStorageService } from "@/components/ts/localStorage";
import { PERMISSION } from '@/store/mutation-types'

const props = defineProps({
  collapsed: Boolean,
})
const route = useRoute();
const permission = LocalStorageService.get(PERMISSION)
console.log(menuRoutes.children,111);
const routes = JSON.stringify(menuRoutes.children)
const orginRoutes = ref([])
orginRoutes.value = filteRouterPermission(JSON.parse(routes), permission)
const getPath = (item) => {
  if (item.meta.type) {
    return item.path + "?type=" + item.meta.type;
  }
  return item.path;
}
let selectedKeys = ref([route.fullPath])
watch(
  () => route,
  (route) => {
    selectedKeys.value = [route.fullPath];
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>

<style lang="less" scoped>
.logo {
  line-height: 40px;
  font-size: 24px;
  color: #fff;
}
</style>