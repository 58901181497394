import request from '@/utils/request'

/**-----------------------------医生------------------------------------------ */
// 创建医生
export const createDentist: any = (data: any) => {
    return request({
        url: '/dentist',
        method: 'post',
        data
    })
}

export const reviewDentist: any = (data: any) => {
    return request({
        url: `/dentist/review`,
        method: 'put',
        data,
    })
}
// 搜索所有医生
export const searchDentist: any = (data: any) => {
    return request({
        url: '/dentist/search',
        method: 'post',
        data
    })
}

// 根据id查询医生
export const getDentistInfo: any = (id: any) => {
    return request({
        url: `/dentist/${id}`,
        method: 'get',
    })
}
// 修改医生信息
export const updateDentist: any = (data: any) => {
    return request({
        url: `/dentist/updateDentist`,
        method: 'post',
        data
    })
}
export const getPosition: any = (id: any) => {
    return request({
        url: `/position/${id}`,
        method: 'get',
    })
}

export const groupDentistReviewStatus: any = () => {
    return request({
        url: `/dentist/groupDentistReviewStatus`,
        method: 'get',
    })
}
