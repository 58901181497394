import axios from 'axios'
import { message } from 'ant-design-vue';
import router from '@/router';

const service = axios.create({
   baseURL:'/api',
   timeout: 60000
})

// 配置请求拦截器
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    if (token) {
      config.headers['Authorization'] = token
    }
    const auth_role = localStorage.getItem('AUTH_ROLE');
    if (auth_role) {
      config.headers['auth-role'] = auth_role
    }
    return config
  }
)


// 响应拦截器
service.interceptors.response.use(
config => {
    const response = config.data;
    if(response.errorMessage && response.errorMessage.code == 401){
      message.error(response.errorMessage.msg);
      localStorage.removeItem('ACCESS_TOKEN');
      router.push({
        path: '/login',
      });
      return Promise.reject(new Error(response.errorMessage.msg || '未知错误'))
    }
    if(response.errorMessage && response.errorMessage.code != 0){
        message.error(response.errorMessage.msg);
       return Promise.reject(new Error(response.errorMessage.msg || '未知错误'))
    }
    if(config.config.responseType == 'blob'){
      return config
    }
    return response.data
  }, 
e => {
    console.log(e.response);
    const error = e.response? e.response.data.errorMessage.msg :'服务器链接失败'
    message.error(error);
    return Promise.reject(e.response?e.response.data:e.response)
})
export default service