import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import "./assets/style/components.less";
import store from './store'
//导入组件库
import * as antIcons from '@ant-design/icons-vue'
import { LocalStorageService } from './components/ts/localStorage'
import {PERMISSION} from '@/store/mutation-types'
const app = createApp(App);
// 注册组件
Object.keys(antIcons).forEach(key => {
    app.component(key, antIcons[key as keyof typeof antIcons])
})
app.directive('btnPermission', (el, binding) => {
    const permission = LocalStorageService.get(PERMISSION)
    if(!permission.includes(binding.value)){
        el.classList.add('is-disabled')
    }
})
// 添加到全局
app.config.globalProperties.$antIcons = antIcons
app.use(store).use(router).use(Antd).mount('#app')
