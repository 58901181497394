// import NProgress from 'nprogress' // progress bar
// import '@/components/NProgress/nprogress.less' // progress bar custom style
import {ACCESS_TOKEN, PERMISSION, USER_INFO} from '@/store/mutation-types'
import { hasPermission } from './permission'
// import ls from '@/utils/Storage'
// import { setDocumentTitle } from '@/utils/domUtil'
import type { Router } from 'vue-router';
import { LocalStorageService } from "@/components/ts/localStorage";
import {message} from "ant-design-vue";

// NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['login', 'register', 'registerResult','pic'] // 不进行拦截的路由名称集合
const defaultRoutePath = '/home'

export const setupBeforeEach = (router: Router) => {
  router.beforeEach((to, from, next) => {
    // NProgress.start() // 加载进度条
    // setDocumentTitle(to)
    // console.log('跳转到首页',LocalStorageService.get(ACCESS_TOKEN) && LocalStorageService.get(PERMISSION));
    const userInfo: any = LocalStorageService.get(USER_INFO);
    if (LocalStorageService.get(ACCESS_TOKEN) && LocalStorageService.get(PERMISSION)) {
      /* has token */
      if (to.path === '/login' || to.path === '/pic') {
        next()
        // NProgress.done()
      } else {
        const permission = LocalStorageService.get(PERMISSION)
        const canAccess = hasPermission(permission, to)
        if (canAccess) {
          next()
        } else {
          next({ path: '/exception/403' })
        }
      }
    } else {
      if (whiteList.includes(to.name as any)) {
        // 在免登录白名单，直接进入
        next()
      } else {
        console.log('跳转到登录页');
        next({ path: '/login', query: { redirect: to.fullPath } })
        // NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
      }
    }
  })
}

export const setupAfterEach = (router: Router) => {
  router.afterEach(() => {
    // NProgress.done() // finish progress bar
  })
}
