import request from '@/utils/request'
// 创建流程
export const createProcess: any = (data: any) => {
    return request({
        url: '/process',
        method: 'post',
        data
    })
}

// export const searchProcess: any = (data: any) => {
//     return request({
//         url: '/process/search',
//         method: 'post',
//         data
//     })
// }
export const searchProcess: any = () => {
    return request({
        url: '/bpmn/process',
        method: 'get',
    })
}

export const updateProcess: any = (data: any) => {
    return request({
        url: '/process',
        method: 'put',
        data
    })
}


export const startProcess: any = (data:any) =>{
    return request({
        url: '/bpmn/start/process',
        method: 'post',
        data
    })
}

export const getProcess: any = (id: any) => {
    return request({
        url: `/process/${id}`,
        method: 'get',
    })
}

export const deleteProcess: any = (id: number) => {
    return request({
        url: `/process/${id}`,
        method: 'delete',
    })
}

//根据患者id获取流程历史
export const getProcessHistoryList: any = (patientId: string) => {
    return request({
        url: `/order/doSearchOrdersWithSort/${patientId}`,
        method: 'get',
    })
}
// 根据订单id获取历史记录
export const getProcessHistory: any = (orderId: string) => {
    return request({
        url: `/bpmn/history/${orderId}`,
        method: 'get',
    })
}