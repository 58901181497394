import request from '@/utils/request'

//用户登录
export const login:any = (data: any) => {
    return request({
      url: '/auth/login',
      method: 'post',
      data
    })
  }

//用户登录
export const logout:any = (data: any) => {
    return request({
      url: '/auth/logout',
      method: 'post',
      data
    })
  }

// 获取用户登录信息
export const userInfo:any = () => {
  return request({
    url: '/auth/userinfo',
    method: 'get',
  })
}

// 获取用户当前菜单权限
export const userMenus:any = (userId:number) => {
  return request({
    url:`/sys/user/userMenus/${userId}`
  })
}
// 获取oss上传权限
export const ossToken:any = () => {
  return request({
    url:`/oss/a/b`,
    method: 'get',
  })
}
// oss下载
export const downloadZip:any = (data: any) => {
  return request({
    url:`/oss/download/dir`,
    method: 'post',
    data,
    responseType: 'blob'// 表明返回服务器返回的数据类型
  })
}
//获取验证码
export const verificationCode:any = (data: any) => {
  return request({
    url: '/sms/send/verificationCode',
    method: 'post',
    data
  })
}
//校验验证码
export const smsVerify:any = (data: any) => {
  return request({
    url: '/sms/verify',
    method: 'post',
    data
  })
}
//校验验证码
export const dictList:any = (data: any) => {
  return request({
    url: '/sys/dict/list/item',
    method: 'post',
    data
  })
}
