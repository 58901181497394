import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "AddPatient" }
const _hoisted_2 = { class: "warp-left" }
const _hoisted_3 = { class: "warp-conen" }
const _hoisted_4 = { class: "warp-right" }
const _hoisted_5 = { class: "carousel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadImage = _resolveComponent("UploadImage")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_AddHospitalVue = _resolveComponent("AddHospitalVue")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      model: _ctx.formState,
      "label-col": _ctx.labelCol,
      rules: _ctx.rules,
      "wrapper-col": _ctx.wrapperCol,
      autoComplete: "off",
      ref: "refAddPatient"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_UploadImage, {
              onOnSuccess: _ctx.onSuccess,
              imageUrl: _ctx.data.docs && _ctx.data.docs.length > 0 &&  _ctx.data.docs.filter(e=>e.type==='AVATAR').length>0? _ctx.data.docs.filter(e=>e.type==='AVATAR')[0].path :''
            }, null, 8, ["onOnSuccess", "imageUrl"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_form_item, {
              label: "姓名",
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "生日",
              name: "born"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_date_picker, {
                  value: _ctx.formState.born,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.born) = $event)),
                  "disabled-date": _ctx.disabledDate,
                  valueFormat: "YYYY-MM-DD"
                }, null, 8, ["value", "disabled-date"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "性别" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.formState.gender,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.gender) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, { value: "Male" }, {
                      default: _withCtx(() => [
                        _createTextVNode("男")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, { value: "Female" }, {
                      default: _withCtx(() => [
                        _createTextVNode("女")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_a_form_item, {
            label: "手机号",
            name: "mobile"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.mobile,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.mobile) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "身份证",
            name: "idNumber"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.idNumber,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.idNumber) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "邮箱",
            name: "email"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.email,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.email) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "来源",
            name: "src"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                options: _ctx.data.sourceType,
                value: _ctx.data.formState.src,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.formState.src) = $event)),
                placeholder: "来源",
                "field-names": { label: 'name', value: 'id', options: 'children' }
              }, null, 8, ["options", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "标签",
            name: "tagIds"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                options: _ctx.data.tagList,
                mode: "multiple",
                value: _ctx.data.formState.tagIds,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.data.formState.tagIds) = $event)),
                placeholder: "标签",
                "field-names": { label: 'name', value: 'id', options: 'children' }
              }, null, 8, ["options", "value"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["model", "label-col", "rules", "wrapper-col"]),
    (_ctx.data.addHospitalvisible)
      ? (_openBlock(), _createBlock(_component_a_modal, {
          key: 0,
          visible: _ctx.data.addHospitalvisible,
          "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.data.addHospitalvisible) = $event)),
          title: _ctx.data.title,
          footer: null
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AddHospitalVue, { onOnCancel: _ctx.onCancel }, null, 8, ["onOnCancel"])
          ]),
          _: 1
        }, 8, ["visible", "title"]))
      : _createCommentVNode("", true)
  ]))
}