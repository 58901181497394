<template>
  <a-upload
    v-model:file-list="fileList"
    name="avatar"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :before-upload="beforeUpload"
    :customRequest="handleChange"
    :accept="acceptString"
  >
    <img v-if="url" :src="url" alt="avatar" />
    <div v-else>
      <loading-outlined v-if="loading"></loading-outlined>
      <plus-outlined v-else></plus-outlined>
    </div>
  </a-upload>
</template>

<script>
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref,onMounted, watch } from 'vue';
// import type { UploadChangeParam, UploadProps } from 'ant-design-vue';
import {setItem, getItem,isExpiration} from '../js/localStorage.js';
import {getUUID} from '@/components/ts/components'
import OSS from 'ali-oss'
// import axios from 'axios'
import {ossToken} from "../../api/user";

export default defineComponent({
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  props:{
    // ['imageUrl','accept']
    imageUrl:{
      type:String,
      default:''
    },
    accept:{
      type:String,
      default:"image/png, image/jpeg"
    },
    fileName:{
      type:String,
      default:""
    }
  },
  setup(props, { emit }) {
    const fileList = ref([]);
    const loading = ref(false);
    const url = ref('');
    const acceptString = ref(props.accept);
    console.log(acceptString.value);
    let sts = {
      region:'',
      accessKey:'',
      accessSecret:'',
      token:'',
      bucket:'',
      endpoint:''
    }
    const handleChange = (info) => {
        console.log(info);
        requestFile(info);
    };
    
    const requestFile = async (file) => {
      console.log(file);
      let stsStr = isExpiration("sts");
      console.log('stsStr',stsStr);
      if(!stsStr){
          let res = await ossToken();
          sts = res
          setItem({
              name:"sts",
              value:res
          });
        }else{
          sts = getItem();
          console.log(getItem());
        }
      const client = new OSS({
          region: sts.region,
          accessKeyId: sts.accessKey,
          accessKeySecret: sts.accessSecret,
          stsToken: sts.token,
          bucket: sts.bucket,
          secure:true,
          endpoint:sts.endpoint,
      })
      const name = props.fileName ?`${props.fileName}${file.file.name.substring(file.file.name.lastIndexOf('.'))}` : `/avatar/${getUUID()}${file.file.name.substring(file.file.name.lastIndexOf('.'))}`
      const res = await client.multipartUpload(name,file.file,{headers: {"Access-Control-Allow-Origin": "*"},});
      console.log(res);
      url.value = res.res.requestUrls[0].split('?')[0]
      emit('onSuccess', url.value)
      return;
    };

    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
      }
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isLt2M) {
      //   message.error('Image must smaller than 2MB!');
      // }
      return isJpgOrPng;
    };

    watch(() => props.imageUrl,(newVal)=>{
      console.log('图片路径发生改变了',newVal);
      url.value = props.imageUrl
    })
    return {
      fileList,
      loading,
      url,
      sts,
      acceptString,
      handleChange,
      beforeUpload,
    };
  },
});
</script>
<style lang="less">
.avatar-uploader > .ant-upload.ant-upload-select {
  width: 178px;
  height: 178px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card {
  margin-top: 8px;
  color: #666;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
