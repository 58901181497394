import { notification } from 'ant-design-vue';
/**
 * 复制单行内容到粘贴板
 * content : 需要复制的内容
 * message : 复制完后的提示，不传则默认提示"复制成功"
 */
export function copyToClip (content:string){
    return new Promise((resolve, reject) => {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(content)
                .then(() => {
                    console.log('复制成功');
                    notification.success({
                        message: '提示',
                        description:'已经复制到剪贴板（'+content+'）'
                    })
                })
        }else{
            const input = document.createElement('input')
            document.body.appendChild(input)
            input.setAttribute('value', content)
            input.select()
            if (document.execCommand('copy')) {
                document.execCommand('copy')
            }
            document.body.removeChild(input)
            console.log('复制成功')
            notification.success({
                message: '提示',
                description:'已经复制到剪贴板（'+content+'）'
            })
        }
    })
}