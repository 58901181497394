export function setItem(params){
  //将obj和传进来的params合并
//   Object.assign(options,obj,params);
  if(params.expires){
  //如果options.expires设置了的话
  //以options.name为key，options为值放进去
      localStorage.setItem(params.name,JSON.stringify(params));
  }else{
  //如果options.expires没有设置，就判断一下value的类型
      const type = Object.prototype.toString.call(params.value);
      //如果value是对象或者数组对象的类型，就先用JSON.stringify转一下，再存进去
      if(type == '[object Object]'){
        params.value = JSON.stringify(params.value);
      }
      if(type == '[object Array]'){
        params.value = JSON.stringify(params.value);
      }
      localStorage.setItem(params.name,params.value);
  }
}

let localStorageItem = {}

export function isExpiration(name){
    localStorageItem = {};
    let item = localStorage.getItem(name);
    if(!item){
      return false
    }
    //先将拿到的试着进行json转为对象的形式
    try{
        item = JSON.parse(item);
    }catch(error){
    //如果不行就不是json的字符串，就直接返回
        // item = item;
        return false;
    }
    const date = new Date();
    //何时将值取出减去刚存入的时间，与item.expires比较，如果大于就是过期了，如果小于或等于就还没过期
    console.log("过期时间还剩多久",date,new Date(item.expiration),new Date(item.expiration) - date);
    if(new Date(item.expiration) - date <= 6000){
    //缓存过期，清除缓存，返回false
        localStorage.removeItem(name);
        return false;
    }else{
    //缓存未过期，返回值
        localStorageItem = item
        return true;
    }
}

export function getItem(){
  return localStorageItem;
}

export function removeItem(name){
    localStorage.removeItem(name);
}