
import {Rule} from "ant-design-vue/lib/form";
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    toRefs,
    UnwrapRef,
} from "vue";
import areaJson from "@/assets/area.json";
import {createClinic, getClinicInfo, updateClinic} from "@/api/clinic";
import {message} from "ant-design-vue";
import {getParentNodes} from "@/components/ts/components";
import STATUS from "@/components/model/enum";
import {isMobile, isTelephone} from "@/utils/validate";
import UploadImagePreview from "@/components/view/UploadImagePreview.vue";

interface FormState {
    shortName: string;
    fullName: string;
    license: string;
    businessData: string;
    tel: string;
    areaId: string;
    addressId: string;
    address: string;
    id: string;
    contact: string,
    clinicLicense: any,
    clinicMedicalLicense: any,
    contactPosition: string,
    contactPhone: string,
    parentId: string,
    // firmId:number
}

interface Options {
    options1: any;
    value1: any[];
    disabled: boolean;
}

export default defineComponent({
    name: "Hospital",
    emits: ["onCancel"],
    props: ["id", "type", "license"],
    setup(props, context) {
        const data = reactive({
            loading: false,
        });
        let formState: UnwrapRef<FormState> = reactive({
            shortName: "",
            fullName: "",
            license: "",
            businessData: "",
            tel: "",
            areaId: "",
            addressId: "",
            address: "",
            id: "",
            contact: "",
            contactPosition: "",
            contactPhone: "",
            parentId: "",
            clinicLicense: {
                title: "诊所营业执照",
                path: "",
                type: "CLINIC_LICENSE",
                id: ''
            },
            clinicMedicalLicense: {
                title: "医疗许可证",
                path: "",
                type: "CLINIC_MEDICAL_LICENSE",
                id: ''
            },
            // firmId:0
        });
        const rules: Record<string, Rule[]> = {
            shortName: [
                {
                    required: true,
                    message: "请填写机构名称!",
                    trigger: "change",
                },
            ],
            areaId: [{required: true, message: "请选择地区!", trigger: "change"}],
            address: [{required: true, message: "请填写地址!", trigger: "change"}],
            businessData: [{required: true, message: "请选择医院类型!", trigger: "change"}],
            clinicLicense: [{
                 required: true,
                 trigger: "change",
                 validator: (rule, value) => {
                        if (value.path == "") {
                            return Promise.reject("请上传营业执照!");
                        }
                        return Promise.resolve();
                },}],
            clinicMedicalLicense: [{
                required: true, 
                validator: (rule, value) => {
                        if (value.path == "") {
                            return Promise.reject("请上传医疗许可证!");
                        }
                        return Promise.resolve();
                },
                trigger: "change"}],
            contact: [{required: true, message: "请填写联系人姓名!", trigger: "change"}],
            contactPosition: [{required: true, message: "请填写联系人职位!", trigger: "change"}],
            contactPhone: [{
                required: true,
                trigger: "change",
                validator: (rule, value) => {
                    if (value == "") {
                        return Promise.reject("请填写联系人电话");
                    }
                    if (!isMobile(value)) {
                        return Promise.reject("联系人电话格式错误");
                    } else {
                        return Promise.resolve();
                    }
                },
            },],
            license: [
                {required: true, message: "请输入机构代码!", trigger: "change"},
            ],
            tel: [
                {
                    required: false,
                    trigger: "change",
                    validator: (rule, value) => {
                        if (value == "") {
                            return Promise.resolve();
                        }
                        if (!isTelephone(value)) {
                            return Promise.reject("电话号码格式错误");
                        } else {
                            return Promise.resolve();
                        }
                    },
                },
            ],
        };
        const options: UnwrapRef<Options> = reactive({
            options1: areaJson,
            value1: [],
            disabled: false,
        });
        const handleChange = (value: string) => {
            console.log(areaJson);
            formState.areaId = value[value.length - 1];
        };
        const refAddHospital = ref()
        const onSubmit = async () => {
            refAddHospital.value.validate().then(() => {
                console.log('验证');
                if (props.type == 'update') {
                    update(formState)
                } else {
                    add(formState)
                }
            }).catch(() => {
                context.emit("onCancel", false);
            })

        };

        const add = (option: any) => {
            createClinic(option)
                .then((res: any) => {
                    message.success("创建成功");
                    context.emit("onCancel", true);
                })
                .catch(() => {
                    context.emit("onCancel", false);
                });
        }

        const update = (option: any) => {
            updateClinic(option).then(() => {
                console.log('修改成功');
                context.emit("onCancel", true);
            })
                .catch(() => {
                    context.emit("onCancel", false);
                });
        }

        const getAreaNode = (id: string) => {
            let ids = []
            //let ids = ['310112', '3101', '31']
            for (let i = 1; i <= id.length / 2; i++) {
                const code = id.substring(0, i * 2)
                ids.push(Number(code))
            }
            return ids;
        }

        const handleCancel = () => {
            console.log("取消");
            context.emit("onCancel");
        };
        const init = async () => {
            console.log(props);
            if (props.type == "update") {
                options.disabled = true;
                const res = await getClinicInfo(props.id);
                for (const key in formState) {
                    formState[key as keyof typeof formState] = res[key];
                }
                console.log(res,'图片');
                if(res.docBos){
                    res.docBos.forEach((element:any) => {
                        if(element.type == "CLINIC_LICENSE"){
                            formState.clinicLicense = {
                                title: "诊所营业执照",
                                path: element?.path,
                                id:element?.id,
                                type: "CLINIC_LICENSE",
                            }
                        }
                        if(element.type == "CLINIC_MEDICAL_LICENSE"){
                            formState.clinicMedicalLicense = {
                                title: "医疗许可证",
                                path: element?.path,
                                id:element?.id,
                                type: "CLINIC_MEDICAL_LICENSE",
                            }
                        }
                    });
                }
                formState.id = res.id
                formState.areaId = res.addressBo.areaId
                if (formState.areaId) {
                    let nodes = getAreaNode(formState.areaId)
                    console.log("found it..", nodes)
                    options.value1 = nodes
                }
                formState.contact = res.contact.name
                formState.contactPosition = res.contactPosition
                formState.contactPhone = res.contact.mobile
            } else if (props.type == "add") {
                options.disabled = false;
            } else {
                formState.parentId = props.id
                formState.license = props.license
                options.disabled = true
            }
        };
        // 上传成功回调
        const onClinicLicenseSuccess = (url: string) => {
            console.log(url);
            formState.clinicLicense.path = url;
            refAddHospital.value.validate("clinicLicense")
        };
        const onClinicLicenseRemove = () => {
            formState.clinicLicense.path = '';
            refAddHospital.value.validate("clinicLicense")
        }
        // 上传成功回调
        const onClinicMedicalLicenseSuccess = (url: string) => {
            console.log(url);
            formState.clinicMedicalLicense.path = url;
            refAddHospital.value.validate("clinicMedicalLicense")
        };
        const onClinicMedicalLicenseRemove = () => {
            formState.clinicMedicalLicense.path = '';
            refAddHospital.value.validate("clinicMedicalLicense")
        }
        onMounted(async () => {
            await init();
        });
        return {
            ...toRefs(data),
            ...toRefs(options),
            labelCol: {style: {width: "150px"}},
            wrapperCol: {span: 14},
            formState,
            refAddHospital,
            rules,
            data,
            onSubmit,
            handleChange,
            handleCancel,
            STATUS,
            onClinicLicenseSuccess,
            onClinicLicenseRemove,
            onClinicMedicalLicenseSuccess,
            onClinicMedicalLicenseRemove,
        };
    },
    components: {UploadImagePreview},
});
