
//导出store模块
export default {
    namespaced: true,
    state: () => ({
        token: localStorage.getItem('token') || '测试token',
        userinfo: '测试userinfo'
        // userinfo: localStorage.getItem('userinfo')||'测试userinfo'
    }),
    mutations: {
        setToken(state: { token: any; }, token: string) {
            state.token = token;
            localStorage.setItem('token', token)
        },
        setUserInfo(state:any, userinfo:any) {
            state.userinfo = userinfo
            localStorage.setItem('userinfo', userinfo)
        }
    },
    actions: {}
}

