import request from '@/utils/request'

/**-----------------------------患者------------------------------------------ */
// 创建患者
export const createPatient:any = (data: any) => {
    return request({
      url: '/patient',
      method: 'post',
      data
    })
  }

  // 查询患者
export const searchPatient:any = (data: any) => {
  return request({
    url: '/patient/search',
    method: 'post',
    data
  })
}

// 根据id查询患者
export const getPatientInfo:any = (id: any) => {
  return request({
    url: `/patient/${id}`,
    method: 'get',
  })
}
// 更改患者信息
export const updatePatient:any = (data: any) => {
  return request({
    url: `/patient`,
    method: 'put',
    data
  })
}



export const getDentistPatientsInCharge:any = (dentistId: any) => {
  return request({
    url: `/patient/dentist/${dentistId}/inCharge`,
    method: 'get'
  })
}


export const setPatientInCharge:any = (id: any, dentistId: any) => {
  return request({
    url: `/patient/id/${id}/inCharge/${dentistId}`,
    method: 'put'
  })
}

// 根据id查询患者
export const getCaseInfo:any = (id: any) => {
  return request({
    url: `/patient/case/${id}`,
    method: 'get',
  })
}






