
import { Options, Vue } from "vue-class-component";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
// import Menu from "@/components/menu.vue"
import Header from "@/components/view/Header.vue";
import Sider from "@/components/Sider/index.vue";
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "APP",
  components: {
    Header,
    Sider,
  },
  setup() {
    let collapsed = ref(false);
    const locale = zhCN;
    return { collapsed, locale };
  },
});
// export default class Home extends Vue {}
