
import { defineComponent, onMounted, reactive, ref, toRaw } from "vue";
import type { UnwrapRef } from "vue";
import UploadImage from "@/components/view/UploadImage.vue";
import dayjs, { Dayjs } from "dayjs";
import { message } from "ant-design-vue";
import { createPatient, getPatientInfo, updatePatient } from "@/api/patient";
import { getClinics, searchClinic } from "@/api/clinic";
import AddHospitalVue from "../hospital/addHospital.vue";
import { Rule } from "ant-design-vue/lib/form";
import { getDate, age_Conversion } from "@/components/ts/components";
import { searchDentist } from "@/api/doctor";
import { isMobile, cardid, isEmail } from "@/utils/validate"
import { dictList } from "@/api/user";
interface FormState {
  name: string;
  mobile: string;
  gender: string;
  idNumber: string;
  born: string;
  email: string;
  code: string;
}

interface Data {
  title: string;
  url: string;
  hospitalOption: any[];
  addHospitalvisible: boolean;
  dentistSelectLoading: boolean;
  clinicSelectLoading: boolean;
  formState: {
    dentistId: string;
    clinicId: string;
    patientId: string;
    src: string,
    tagIds: any[],
    id:string
    // source: number;
  };
  disabled: boolean;
  doctorOption: any[];
  info: {
    dentistName: string;
    clinicName: string;
  },
  buttonText: string,
  docs: any[],
  sourceType: any[],
  tagList: any[]
}

let timeout: any;
let currentValue = "";
function fetch(value: string, type: string, callback: any) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  function fake() {
    const options = {
      page: {
        pageSize: 100,
        currPage: 1,
      },
      keyword: value,
    };
    if (type == 'clinic') {
      searchClinic(options).then((res: any) => {
        if (currentValue === value) {
          const data: any[] = [];
          console.log(res);
          if (res) {
            const result = res.data;
            result.forEach((r: any) => {
              data.push({
                value: r.id,
                label: r.firm.shortName,
              });
            });
          }
          callback(data);
        }
      });
    } else {
      searchDentist(options).then((res: any) => {
        if (currentValue === value) {
          const data: any[] = [];
          console.log(res);
          if (res) {
            const result = res.data;
            result.forEach((r: any) => {
              data.push({
                value: r.id,
                label: r.name,
              });
            });
          }
          callback(data);
        }
      });
    }
  }

  timeout = setTimeout(fake, 300);
}
function getClinicsByDentistId(value: string, callback: any) {
  if (value) {
    getClinics(value).then((res: any) => {
      let result: any[] = [];
      if (res) {
        result = res.map((e: any) => {
          return {
            value: e.id,
            label: e.firm.fullName,
          }
        })
      }
      callback(result);
    })
  }
}

export default defineComponent({
  name: "AddPatient",
  components: {
    UploadImage,
    AddHospitalVue,
  },
  props: ["id", "type"],
  emits: ["onCancel"],
  setup(props, context) {
    const formState: UnwrapRef<FormState> = reactive({
      name: "",
      mobile: "",
      gender: "Male",
      idNumber: "",
      born: "",
      email: "",
      id: "",
      code: '',
    });
    const data: UnwrapRef<Data> = reactive({
      title: "新建医生",
      url: "",
      formState: {
        dentistId: '',
        clinicId: "",
        patientId: "",
        src: '',
        tagIds: [],
        id:''
        //默认设置来源为1|WEB
        // source: 1
      },
      hospitalOption: [],
      addHospitalvisible: false,
      clinicSelectLoading: false,
      dentistSelectLoading: false,
      disabled: false,
      doctorOption: [],
      info: {
        dentistName: '',
        clinicName: "",
      },
      docs: [
        {
          type: 'AVATAR',
          path: '',
        },
      ],
      buttonText: '发送验证码',
      sourceType: [],
      tagList: []
    });
    let validate = async (value: string, message: string) => {
      console.log(data.formState[value as keyof typeof data.formState]);
      if (data.formState[value as keyof typeof data.formState].length < 1) {
        return Promise.reject(message);
      } else
      {
        return Promise.resolve();
      }
    };
    const rules: Record<string, Rule[]> = {
      name: [
        {
          required: true,
          trigger: "change",
          validator: (rule, value) => {
            if (!value) {
              return Promise.reject("请输入姓名!");
            }
            if (value.length > 32 || value.length < 2) {
              return Promise.reject("姓名长度应为2-32个字!");
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      mobile: [
        {
          required: true,
          trigger: "change",
          validator: (rule, value) => {
            if (value == '') {
              return Promise.reject("请输入手机号!");
            }
            if (!isMobile(value)) {
              return Promise.reject("手机号格式错误!");
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      idNumber: [
        {
          required: false,
          trigger: "change",
          validator: (rule, value) => {
            if (!value) {
              return Promise.resolve();
            }
            if (!cardid(value)) {
              return Promise.reject("身份证格式错误!");
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      email: [
        {
          required: false,
          trigger: "change",
          validator: (rule, value) => {
            if (!value) {
              return Promise.resolve();
            }
            if (!isEmail(value)) {
              return Promise.reject("邮箱格式错误!");
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      clinicId: [
        {
          required: true,
          validator: () => validate("clinicId", "请选择所属医院!"),
          trigger: "change",
        },
      ],
      dentistId: [
        {
          required: true,
          validator: () => validate("dentistId", "请选择就诊医生!"),
          trigger: "change",
        },
      ],
      born: [
        {
          required: false,
          trigger: "change",
          validator: (rule, value) => {
            if (!value) {
              return Promise.resolve();
            }
            console.log(age_Conversion(value));
            // if (age_Conversion(value) < 8) {
            //   return Promise.reject("年龄不能少于8周岁!");
            // } else {
            return Promise.resolve();
            // }
          },
        },
      ],
      code: [
        {
          required: true,
          message: '请输入验证码',
          trigger: "change",
        },
      ],
      src: [
        {
          required: true,
          validator: () => validate("src", "请选择来源!"),
          trigger: "change",
        },
      ],
      tagIds: [
        {
          required: true,
          validator: () => validate("tagIds", "请选择标签!"),
          trigger: "change",
        },
      ]
    };
    const refAddPatient = ref();
    const onSubmit = async () => {
      let options = {
        ...formState,
        docs: data.docs,
        ...data.formState
      };
      refAddPatient.value.validate().then(() => {
        console.log('校验成功', props);
        console.log("submit!", toRaw(options));
        if (props.type == 'update') {
          update(options);
        } else {
          add(options);
        }
      }).catch(() => {
        console.log('校验失败');
        window.parent.postMessage(
          {
            onHandle: 'onSuccess',
            params: {
              status: false,
            },
          },
          '*',
        );
        // context.emit("onCancel");
      })
    };

    const add = (options: any) => {
      createPatient(options).then((res: any) => {
        console.log(res);
        message.success("创建成功");
        context.emit("onCancel");
        window.parent.postMessage(
          {
            onHandle: 'onSuccess',
            params: {
              status: true,
              id: res
            },
          },
          '*',
        );
      }).catch(() => {
        window.parent.postMessage(
          {
            onHandle: 'onSuccess',
            params: {
              status: false,
            },
          },
          '*',
        );
      })
    }

    const update = (options: any) => {
      console.log("修改患者");
      updatePatient(options).then((res: any) => {
        console.log("修改成功", res);
        message.success("创建成功");
        context.emit("onCancel");
      }).catch(() => {
        window.parent.postMessage(
          {
            onHandle: 'onSuccess',
            params: {
              status: false,
            },
          },
          '*',
        );
      })
    }
    const onSuccess = (val: string) => {
      console.log("返回的路径", val);
      data.docs.filter(e=>e.type==='AVATAR')[0].path = val;
    };

    const searchLeasSrc = () => {
      dictList({
        "dictCodes": []
      }).then((res: any[]) => {
        console.log(res);
        data.sourceType = []
        data.tagList = []
        res.forEach(element => {
          if (element.dictCode == 'CUSTOMER_TYPE') {
            data.sourceType.push(element)
          } else if (element.dictCode == 'CUSTOMER_TAG') {
            data.tagList.push(element)
          }
        });
      });
    }

    // const getVerificationCode = () => {
    //   let options = {
    //     mobile: formState.mobile
    //   }
    //   refAddPatient.value.validate(["mobile"]).then(() => {
    //     data.disabled = true;
    //     verificationCode(options).then(() => {
    //       data.buttonText = "请稍候...";
    //       message.warning("已发送验证码，请查收");
    //       setTimeout(() => {
    //         doLoop(60);
    //       }, 500);
    //     })
    //   })
    // }
    // const doLoop = (seconds: number) => {
    //   console.log(seconds);
    //   seconds = seconds ? seconds : 60;
    //   data.buttonText = seconds + "s后获取";
    //   // this.code = code
    //   let countdown = setInterval(() => {
    //     if (seconds > 0) {
    //       data.buttonText = seconds + "s后获取";
    //       --seconds;
    //     } else {
    //       data.buttonText = "获取验证码";
    //       data.disabled = false;
    //       clearInterval(countdown);
    //     }
    //   }, 1000);
    // };
    /**----------------------------------医院 */
    // 模糊搜索医院
    const handleSearch = (val: string) => {
      fetch(val, 'clinic', (d: any[]) => (data.hospitalOption = d));
    };
    // 切换医院
    const handleChange = (val: string) => {
      console.log("修改医院", val);
      data.formState.clinicId = val
      // fetch(val,'clinic', (d: any[]) => (data.hospitalOption = d));
    };
    // 新建医院
    const addHospital = () => {
      console.log("新建医院");
      data.addHospitalvisible = true;
    };
    const onCancel = () => {
      console.log("取消");
      data.addHospitalvisible = false;
    };

    /*-------------------医生------------------- */
    const handleSearchDoctor = (val: string) => {
      fetch(val, 'doctor', (d: any[]) => (data.doctorOption = d));
    };
    const handleFocusDoctor = () => {
      data.dentistSelectLoading = true;
      fetch(" ", 'doctor', (d: any[]) => { data.doctorOption = d; data.dentistSelectLoading = false });
    };
    const handleChangeDoctor = (val: string) => {
      data.formState.dentistId = val;
      data.clinicSelectLoading = true;
      data.formState.clinicId = "";
      data.info.clinicName = "";
      getClinicsByDentistId(val, (d: any[]) => {
        data.hospitalOption = d;
        if (d.length == 1) {
          data.formState.clinicId = d[0].value;
          data.info.clinicName = d[0].label;
        }
        data.clinicSelectLoading = false;
      })
      // fetch(val,'doctor', (d: any[]) => (data.doctorOption = d));
    }

    const disabledDate = (current: Dayjs) => {
      // Can not select days before today and today
      return current && current > dayjs().endOf("day");
    };

    const handleCancel = () => {
      console.log("取消");
      window.parent.postMessage(
        {
          onHandle: 'onCancel'
        },
        '*',
      );
      context.emit("onCancel");
    };

    const init = async () => {
      console.log(props.type);
      if (props.id) {
        data.disabled = true;
        const res = await getPatientInfo(props.id);
        console.log(res);
        for (const key in formState) {
          formState[key as keyof typeof formState] = res[key];
        }
        formState.born = res.born ? getDate(res.born) : '';
        if (res.docs) {
          data.docs = res.docs
        }
        data.formState.patientId = res.id
        data.formState.id = res.id
        data.formState.src = res.src == null ? '' : res.src
        data.formState.tagIds = res.tagIds == null ? [] : res.tagIds
        // data.formState.dentistId = res.dentists ? res.dentists[0].id : 0
        // data.formState.clinicId = res.dentists ? res.clinics[0].id : 0
        // data.info.dentistName = res.dentists[0].person.name
        // data.info.clinicName = res.clinics[0].firm.shortName
      }
    };
    onMounted(async () => {
      await init();
      await searchLeasSrc();
      // 接受vue传过来的值
      window.addEventListener('message', event => {
        const res = event.data;
        switch (res.iframeClick) {
          case 'submitHandle':
            console.log('接收传过来的值', res.params);
            data.formState.dentistId = res.params.dentistId
            onSubmit();
        }
      });
    });
    return {
      labelCol: { style: { width: "5rem" } },
      wrapperCol: { span: 14 },
      formState,
      data,
      rules,
      refAddPatient,
      onSubmit,
      onSuccess,
      disabledDate,
      handleSearch,
      handleChange,
      addHospital,
      onCancel,
      handleCancel,
      handleSearchDoctor,
      handleChangeDoctor,
      handleFocusDoctor,
      // getVerificationCode
    };
  },
});
