import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sider = _resolveComponent("Sider")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: _ctx.locale }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout, { style: {"min-height":"100vh"} }, {
        default: _withCtx(() => [
          _createVNode(_component_Sider, { collapsed: _ctx.collapsed }, null, 8, ["collapsed"]),
          _createVNode(_component_a_layout, null, {
            default: _withCtx(() => [
              _createVNode(_component_Header, {
                collapsed: _ctx.collapsed,
                onToggleCollapsed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
              }, null, 8, ["collapsed"]),
              _createVNode(_component_a_layout_content, { style: {"margin":"0 16px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view)
                ]),
                _: 1
              }),
              _createVNode(_component_a_layout_footer, { style: {"text-align":"center"} }, {
                default: _withCtx(() => [
                  _createTextVNode(" @上海银马科技有限公司版权所有. 版本：V1.0.0.3 ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["locale"]))
}