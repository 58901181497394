// import { BasicLayout, RouteView } from '@/layouts'
// import { Component } from '@fullcalendar/core/preact'
import { Router } from './types'
import Home from '@/views/Home.vue'
import Patient from "@/views/patient/Patient.vue";

export const example: Router = {
  path: "/",
  name: "index",
  redirect: '/login',
  component: Home,
  children: [
    {
      path: "/home",
      name: "home",
      meta: {
        title: "我的",
        icon: "UserOutlined",
        hidden: false,//导航栏在该页面显示
        permission: ['home'],
      },
      component: () => import('@/views/task/home.vue'),
    },

    {
      path: "/org",
      name: "org",
      meta: {
        title: "组织架构",
        icon: "UsergroupAddOutlined",
        permission: ['org'],
        hidden: false//导航栏在该页面显示
      },
      children: [
        {
          path: '/customer',
          name: 'customer',
          meta: {
            title: "用户",
            icon: 'UsergroupAddOutlined',
            hidden: true,
            permission: ['customer'],
          },
          children: [
            {
              path: "/patient",
              name: "patient",
              meta: {
                title: "客户",
                icon: "UserOutlined",
                permission: ['patient'],
                hidden: false//导航栏在该页面显示
              },
              component: Patient,
            },
            {
              path: '/patient/patientInfo',
              name: 'patientDetail',
              component: () => import('@/views/patient/PatientDetail.vue'),
              meta: {
                title: "患者详情",
                icon: 'UsergroupAddOutlined',
                hidden: true,
                permission: ['patient'],
              },
            },
            {
              path: "/leads",
              name: "leads",
              meta: {
                title: "线索",
                icon: "UserOutlined",
                permission: ['leads'],
                hidden: false
              },
              component: () => import('@/views/leads/home.vue'),
            }
          ]
        },
        {
          path: '/producer',
          name: 'producer',
          component: () => import('@/views/producer/index.vue'),
          meta: {
            title: "生产",
            icon: 'UsergroupAddOutlined',
            hidden: false,
            permission: ['product'],
          },
        },
        {
          path: '/designer',
          name: 'designer',
          component: () => import('@/views/designer/index.vue'),
          meta: {
            title: "设计师团队",
            icon: 'UsergroupAddOutlined',
            hidden: false,
            permission: ['designer'],
          },
        },
        {
          path: '/dentistTeamHome',
          name: 'dentist-team-home',
          component: () => import('@/views/team/home.vue'),
          meta: {
            title: "畸妍堂",
            icon: 'UsergroupAddOutlined',
            permission: ['team'],
            hidden: false
          },
        },
        {
          path: '/dentist/team/view',
          name: 'dentist-team-view',
          component: () => import('@/views/team/home.vue'),
          meta: {
            title: "畸妍堂",
            icon: 'UsergroupAddOutlined',
            permission: ['team'],
            hidden: true
          },
        },
        {
          path: '/dentist/team/scheduling',
          name: 'dentist-team-scheduling',
          component: () => import('@/views/team/scheduling.vue'),
          meta: {
            title: "排班",
            icon: 'UsergroupAddOutlined',
            permission: ['team','Hospital'],
            hidden: true
          },
        },
        {
          path: '/dentist/team/inCharge',
          name: 'dentist-team-inCharge',
          component: () => import('@/views/team/in_charge.vue'),
          meta: {
            title: "分配",
            icon: 'UsergroupAddOutlined',
            permission: ['team'],
            hidden: true
          },
        },
        {
          path: '/operation',
          name: 'operation',
          component: () => import('@/views/administer/organization/List.vue'),
          meta: {
            title: "业务运营",
            icon: 'UsergroupAddOutlined',
            type: 2,
            permission: ['operation'],
            hidden: false
          },
        },
        {
          path: '/doctor/doctorInfo',
          name: 'DoctorDetail',
          component: () => import('@/views/doctor/DoctorDetail.vue'),
          meta: {
            title: "医生详情",
            icon: 'UsergroupAddOutlined',
            hidden: true
          },
        },
        {
          path: '/hospital/hospitalInfo',
          name: 'HospitalDetail',
          component: () => import('@/views/hospital/Detail.vue'),
          meta: {
            title: "医院详情",
            icon: 'UsergroupAddOutlined',
            hidden: true,
            permission: ['Hospital'],
          },
        },
        {
          path: '/hospital/childrenHospital',
          name: 'ChildrenHospital',
          component: () => import('@/views/hospital/ChildrenHospital.vue'),
          meta: {
            title: "分支机构",
            icon: 'UsergroupAddOutlined',
            hidden: true,
            permission: ['Hospital','company'],
          },
        },
        {
          path: "/organization/department",
          name: "department",
          meta: {
            title: "职位",
            icon: "UserOutlined",
            hidden: true,
            permission: ['Hospital','company','operation','product','designer','team'],
          },
          component: () => import('@/views/administer/organization/Department.vue'),
        },
        {
          path: '/hospital',
          name: 'Hospital',
          meta: {
            title: "合作门诊",
            icon: 'MedicineBoxOutlined',
            permission: ['Hospital'],
            hidden: false
          },
          component: () => import('@/views/hospital/Hospital.vue')
        },
        {
          path: "/company",
          name: "company",
          meta: {
            title: "供应商",
            icon: "UserOutlined",
            permission: ['company'],
            type: 3,
            hidden: false//导航栏在该页面显示
          },
          component: () => import('@/views/administer/organization/List.vue'),
        },
      

      ]
    },
    {
      path: '/order/onlineOralExamine',
      name: 'instance-home',
      meta: {
        title: "订单",
        hidden: false,
        icon: 'DatabaseOutlined',
        permission: ['order'],
        type: 1,
      },
      children: [
        {
          path: '/order/onlineOralExamine',
          name: 'online-oral-examine',
          component: () => import('@/views/order/index.vue'),
          meta: {
            title: "口腔检查(线上)",
            hidden: false,
            type: 1,
            icon: 'UsergroupAddOutlined',
            permission: ['onlineOralExamine'],
          },
        },
        {
          path: '/order/faceOrder',
          name: 'face-order',
          component: () => import('@/views/order/faceOrder.vue'),
          meta: {
            title: "面部照订单",
            hidden: false,
            type: 2,
            icon: 'UsergroupAddOutlined',
            permission: ['faceOrder'],
          },
        },
        {
          path: '/order/offlineOralExamine',
          name: 'offline-oral-examine',
          component: () => import('@/views/order/index.vue'),
          meta: {
            title: "口腔检查(线下)",
            hidden: false,
            type: 4,
            icon: 'UsergroupAddOutlined',
            permission: ['offlineOralExamine'],
          },
        },
        {
          path: '/order/oralDiagnosis',
          name: 'oral-diagnosis',
          component: () => import('@/views/order/oralDiagnosis/index.vue'),
          meta: {
            title: "口腔诊断订单",
            hidden: false,
            type: 3,
            icon: 'UsergroupAddOutlined',
            permission: ['oralDiagnosis'],
          },
        },
        {
          path: '/order/designingScheme',
          name: 'designing-scheme',
          component: () => import('@/views/order/designingScheme/index.vue'),
          meta: {
            title: "正畸设计方案订单",
            hidden: false,
            icon: 'UsergroupAddOutlined',
            permission: ['designingScheme'],
          },
        },
        {
          path: '/order/trackOrder',
          name: 'trackOrder',
          component: () => import('@/views/order/trackOrder/index.vue'),
          meta: {
            title: "正畸追踪订单",
            hidden: false,
            icon: 'UsergroupAddOutlined',
            permission: ['trackOrder'],
          },
        },
        {
          path: '/order/saleOrder',
          name: 'saleOrder',
          component: () => import('@/views/order/saleOrder/index.vue'),
          meta: {
            title: "销售订单",
            hidden: false,
            icon: 'UsergroupAddOutlined',
            permission: ['saleOrder'],
          },
        },
        {
          path: '/order/appointment',
          name: 'appointment',
          component: () => import('@/views/order/appointment/index.vue'),
          meta: {
            title: "预约订单",
            hidden: false,
            icon: 'UsergroupAddOutlined',
            permission: ['appointment'],
          },
        },
        {
          path: '/order/processingOrder',
          name: 'processingOrder',

          component: () => import('@/views/order/processingOrder/index.vue'),
          meta: {
            title: "加工订单",
            hidden: false,
            type: 1,
            icon: 'UsergroupAddOutlined',
            permission: ['processingOrder'],
          },
        },
      ]
    },
    {
      path: "/workflow",
      name: "workflow",
      meta: {
        title: "流程",
        icon: 'PartitionOutlined',
        hidden: true,
        permission: ['workflow'],
      },
      children: [
        {
          path: "/workflow/process",
          name: "process",
          meta: {
            title: "流程",
            hidden: false,
            icon: 'UsergroupAddOutlined',
            permission: ['workflowProcess'],
          },
          component: () => import('@/views/workflow/process/home.vue')
        },
        {
          path: "/workflow/task",
          name: "task",
          meta: {
            title: "任务",
            icon: 'UsergroupAddOutlined',
            hidden: false,
            permission: ['workflowTask'],
          },
          component: () => import('@/views/workflow/task/home.vue'),
        },
        {
          path: '/workflow/task/view',
          name: 'task-view',
          component: () => import('@/views/workflow/task/view.vue'),
          meta: {
            title: "任务",
            hidden: true,
            icon: 'UsergroupAddOutlined',
            permission: ['home'],
          },
        },
        {
          path: '/workflow/process/view',
          name: 'process-view',
          component: () => import('@/views/workflow/process/view.vue'),
          meta: {
            title: "详情",
            hidden: true,
            permission: ['home'],
          },
        },
        {
          path: "/task01",
          name: "taskT01",
          meta: {
            title: "线上检测订单",
            hidden: true
          },
          component: () => import('@/views/task/t01.vue'),
        },
        {
          path: "/task29",
          name: "taskT29",
          meta: {
            title: "处方订单",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t29.vue'),
        },
        {
          path: "/task21",
          name: "taskT21",
          meta: {
            title: "全科检测报告",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t21.vue'),
        },
        {
          path: "/task46",
          name: "taskT46",
          meta: {
            title: "正畸检测报告",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t21.vue'),
        },
        {
          path: "/task25",
          name: "taskT25",
          meta: {
            title: "上传STL",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t25.vue'),
        },
        {
          path: "/task26",
          name: "taskT26",
          meta: {
            title: "上传CT",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t26.vue'),
        },
        {
          path: "/task30",
          name: "taskT30",
          meta: {
            title: "数字建模",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t30.vue'),
        },
        {
          path: "/task31",
          name: "taskT31",
          meta: {
            title: "处方模拟",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t30.vue'),
        },
        {
          path: "/task34",
          name: "taskT34",
          meta: {
            title: "扫码支付",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t34.vue'),
        },
        {
          path: "/task36",
          name: "task36",
          meta: {
            title: "临床操作说明",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t36.vue'),
        },
        {
          path: "/task40",
          name: "task40",
          meta: {
            title: "到店确认",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t40.vue'),
        },
        {
          path: "/task42",
          name: "taskT42",
          meta: {
            title: "设置就医科目",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t42.vue'),
        },
        {
          path: "/task43",
          name: "taskT43",
          meta: {
            title: "预约",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t43.vue'),
        },
        {
          path: "/task101",
          name: "task101",
          meta: {
            title: "检测订单选取",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t101.vue'),
        },
        {
          path: "/task103",
          name: "task103",
          meta: {
            title: "选取线上口腔检测订单与面部照订单",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t103.vue'),
        },
        {
          path: "/task104",
          name: "task104",
          meta: {
            title: "选取病人",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t104.vue'),
        },
        {
          path: "/task106",
          name: "task106",
          meta: {
            title: "选取口腔诊断订单",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t106.vue'),
        },
        {
          path: "/task109",
          name: "task109",
          meta: {
            title: "选取医生与诊所",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t109.vue'),
        },
        {
          path: "/task110",
          name: "task110",
          meta: {
            title: "设置付款金额",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t110.vue'),
        },
        {
          path: "/task116",
          name: "task116",
          meta: {
            title: "选择正畸设计方案",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t116.vue'),
        },
        {
          path: "/task118",
          name: "task118",
          meta: {
            title: "监护规则",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t118.vue'),
        },
        {
          path: "/task702",
          name: "task702",
          meta: {
            title: "选取线上口腔检测订单与面部照订单",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t702.vue'),
        },
        {
          path: "/task1110",
          name: "task1110",
          meta: {
            title: "监护分析",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t1110.vue'),
        },
        {
          path: "/task802",
          name: "task802",
          meta: {
            title: "选择监护规则",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t802.vue'),
        },
        {
          path: "/task804",
          name: "task804",
          meta: {
            title: "监护监控",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t804.vue'),
        },
        {
          path: "/task901",
          name: "task901",
          meta: {
            title: "初始化流程",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t901.vue'),
        },
        {
          path: "/task902",
          name: "task902",
          meta: {
            title: "扫码支付",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t902.vue'),
        },
        {
          path: "/task903",
          name: "task903",
          meta: {
            title: "到店确认",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t903.vue'),
        },
        {
          path: "/task905",
          name: "task905",
          meta: {
            title: "退款",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t905.vue'),
        },
        {
          path: "/task1010",
          name: "task1010",
          meta: {
            title: "初始化预约订单",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t1010.vue'),
        },
        {
          path: "/task1070",
          name: "task1070",
          meta: {
            title: "到店确认",
            hidden: true,
            permission: ['home'],
          },
          component: () => import('@/views/task/t1070.vue'),
        },
      ]
    },
    {
      path: "/menu",
      name: "permission",
      meta: {
        title: "权限",
        icon: "SnippetsOutlined",
        hidden: true,
        permission: ['permission']
      },
      children: [
        {
          path: "/menu",
          name: "menu",
          meta: {
            title: "菜单",
            icon: "UserOutlined",
            hidden: false,//导航栏在该页面显示
            permission: ['menuPermission']
          },
          component: () => import('@/views/administer/sysMenu/index.vue'),
        },
        {
          path: "/sysUserRole",
          name: "sysUserRole",
          component: () => import('@/views/administer/sysUserRole/List.vue'),
          meta: {
            title: "角色",
            icon: 'UserOutlined',
            permission: ['sysUserRole'],
            hidden: false//导航栏在该页面显示
          },
        },
        // {
        //   path: '/user/roleDetail',
        //   name: 'RoleDetail',
        //   component: () => import('@/views/administer/sysUserRole/RoleDetail.vue'),
        {
          path: '/workflow/task/view',
          name: 'task-view',
          component: () => import('@/views/workflow/task/view.vue'),
          meta: {
            title: "任务",
            hidden: true,
            icon: 'UsergroupAddOutlined',
            permission: ['home'],
          },
        },
        {
          path: '/workflow/process/view',
          name: 'process-view',
          component: () => import('@/views/workflow/process/view.vue'),
          meta: {
            title: "详情",
            hidden: true,
            permission: ['home'],
          },
        },
        {
          path: "/sys-user",
          name: "sys-user",
          meta: {
            title: "用户",
            icon: "UserOutlined",
            permission: ['sysUser'],
            hidden: false//导航栏在该页面显示
          },
          component: () => import('@/views/permission/user/home.vue'),
        }
      ]
    },
    {
      path: "/testItems",
      name: "testItems",
      meta: {
        title: "设置",
        icon: "SettingOutlined",
        hidden: true,
        permission: ['setting'],
      },
      children: [
        {
          path: "/testItems",
          name: "testItems",
          meta: {
            title: "检测项",
            permission: ['testItems'],
            hidden: false//导航栏在该页面显示
          },
          component: () => import('@/views/setting/testItems/index.vue'),
        },
        {
          path: "/monitorRule",
          name: "monitorRule",
          meta: {
            title: "规则",
            permission: ['monitorRule'],
            hidden: false//导航栏在该页面显示
          },
          component: () => import('@/views/setting/monitorRule/index.vue'),
        },
        {
          path: "/medicalProduct",
          name: "medicalProduct",
          meta: {
            title: "医疗产品",
            hidden: false,
            permission: ['medicalProduct'],
          },
          component: () => import('@/views/setting/medicalProduct/index.vue'),
        },
        {
          path: "/messageControl",
          name: "messageControl",
          meta: {
            title: "消息控制",
            hidden: false,
            permission: ['messageControl'],
          },
          component: () => import('@/views/setting/messageControl/index.vue'),
        },
        {
          path: "/treat",
          name: "treat",
          meta: {
            title: "治疗项",
            hidden: false,
            permission: ['treat'],
          },
          component: () => import('@/views/setting/treat/index.vue'),
        },
        {
          path: "/renew",
          name: "renew",
          meta: {
            title: "更新",
            hidden: false,
            permission: ['renew'],
          },
          component: () => import('@/views/setting/systemRenew/index.vue'),
        },
        {
          path: "/advertisement",
          name: "advertisement",
          meta: {
            title: "广告设置",
            hidden: false,
            permission: ['advertisement'],
          },
          component: () => import('@/views/setting/advertisement/index.vue'),
        },
      ]
    },
    {
      path: "/safety",
      name: "safety",
      meta: {
        title: "安全",
        icon: "SecurityScanOutlined",
        hidden: true,
        permission: ['safety']
      },
      children:[
        {
          path: "/safetyHistory",
          name: "safetyHistory",
          meta: {
            title: "痕迹",
            hidden: false,//导航栏在该页面显示
            permission: ['safetyHistory']
          },
          component: () => import('@/views/safety/safetyHistory.vue'),
        },
      ]
    }
  ],
}
