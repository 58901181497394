/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s: string) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s: string) {
  return /^[1,2][0-9]{10}$/.test(s)
}

// export function isTelephone(s: string) {
//   return /^(0d{2,3})-?(d{7,8})$/.test(s)
// }
/**
 * 电话号码
 * @param {*} s
 */
export function isTelephone (s:string) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}


/**
 * 判断身份证号码
 */
export function cardid(code: string) {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (reg.test(code) === false) {
    return false;
  } else {
    return true;
  }
}