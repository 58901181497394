import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "docs" }
const _hoisted_2 = { class: "docs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_UploadImagePreview = _resolveComponent("UploadImagePreview")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.formState,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol,
          rules: _ctx.rules,
          autoComplete: "off",
          ref: "refAddHospital"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "机构简称",
              name: "shortName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.shortName,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.shortName) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "机构全称" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.fullName,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.fullName) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "机构代码",
              name: "license"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.license,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.license) = $event)),
                  disabled: _ctx.disabled
                }, null, 8, ["value", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "机构电话",
              name: "tel"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.tel,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.tel) = $event)),
                  placeholder: "010-1234567"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "机构地区",
              name: "areaId"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_cascader, {
                  value: _ctx.value1,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value1) = $event)),
                  options: _ctx.options1,
                  placeholder: "请选择地区",
                  "field-names": { label: 'name', value: 'id' },
                  onChange: _ctx.handleChange
                }, null, 8, ["value", "options", "onChange"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "详细地址",
              name: "address"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.address,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.address) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "机构性质",
              name: "businessData"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _ctx.formState.businessData,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.businessData) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select_option, { value: "PRIVATE_DENTAL_CLINIC" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.STATUS("ClinicType").getDesc("PRIVATE_DENTAL_CLINIC")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_select_option, { value: "PRIVATE_DENTAL_OUTPATIENT_CLINIC" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.STATUS("ClinicType").getDesc("PRIVATE_DENTAL_OUTPATIENT_CLINIC")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_select_option, { value: "PRIVATE_SPECIALIZED_DENTAL_HOSPITAL" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.STATUS("ClinicType").getDesc("PRIVATE_SPECIALIZED_DENTAL_HOSPITAL")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_select_option, { value: "PUBLIC_SPECIALIZED_DENTAL_HOSPITAL" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.STATUS("ClinicType").getDesc("PUBLIC_SPECIALIZED_DENTAL_HOSPITAL")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_select_option, { value: "PRIVATE_COMPREHENSIVE_DENTAL_HOSPITAL" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.STATUS("ClinicType").getDesc("PRIVATE_COMPREHENSIVE_DENTAL_HOSPITAL")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_select_option, { value: "PUBLIC_COMPREHENSIVE_DENTAL_HOSPITAL" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.STATUS("ClinicType").getDesc("PUBLIC_COMPREHENSIVE_DENTAL_HOSPITAL")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "营业执照",
              name: "clinicLicense"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_UploadImagePreview, {
                      path: _ctx.formState.clinicLicense?.path,
                      fileUrl: '/avatar',
                      onOnSuccess: _cache[7] || (_cache[7] = (url) => _ctx.onClinicLicenseSuccess(url)),
                      onOnRemove: _ctx.onClinicLicenseRemove
                    }, null, 8, ["path", "onOnRemove"]),
                    _createElementVNode("span", null, _toDisplayString(_ctx.formState.clinicLicense?.title), 1)
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "医疗许可证",
              name: "clinicMedicalLicense"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_UploadImagePreview, {
                      path: _ctx.formState.clinicMedicalLicense?.path,
                      fileUrl: '/avatar',
                      onOnSuccess: _cache[8] || (_cache[8] = (url) => _ctx.onClinicMedicalLicenseSuccess(url)),
                      onOnRemove: _ctx.onClinicMedicalLicenseRemove
                    }, null, 8, ["path", "onOnRemove"]),
                    _createElementVNode("span", null, _toDisplayString(_ctx.formState.clinicMedicalLicense?.title), 1)
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "联系人姓名",
              name: "contact"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.contact,
                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formState.contact) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "联系人职位",
              name: "contactPosition"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.contactPosition,
                  "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formState.contactPosition) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "联系人电话",
              name: "contactPhone"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.contactPhone,
                  "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formState.contactPhone) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "label-col", "wrapper-col", "rules"])
      ]),
      _: 1
    })
  ]))
}