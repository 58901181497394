const ClinicType = {
  Clinic: ['Clinic', '诊所'],
  Dentistry: ['Dentistry', '口腔科'],
  Hospital: ['Hospital', '医院'],
  PRIVATE_DENTAL_CLINIC:["PRIVATE_DENTAL_CLINIC","民营口腔诊所"],
  PRIVATE_DENTAL_OUTPATIENT_CLINIC:["PRIVATE_DENTAL_OUTPATIENT_CLINIC","民营口腔门诊"],
  PRIVATE_SPECIALIZED_DENTAL_HOSPITAL:["PRIVATE_SPECIALIZED_DENTAL_HOSPITAL","民营专科口腔医院"],
  PUBLIC_SPECIALIZED_DENTAL_HOSPITAL:["PUBLIC_SPECIALIZED_DENTAL_HOSPITAL","公立专科口腔医院"],
  PRIVATE_COMPREHENSIVE_DENTAL_HOSPITAL:["PRIVATE_COMPREHENSIVE_DENTAL_HOSPITAL","民营综合口腔医院"],
  PUBLIC_COMPREHENSIVE_DENTAL_HOSPITAL:["PUBLIC_COMPREHENSIVE_DENTAL_HOSPITAL","公立综合口腔医院"],
}
const levelType = {
  Junior: ['Junior', '初级(住院医师)'],
  Intermediate: ['Intermediate', '中级(主治医师)'],
  Senior: ['Senior', '副高级(副主任医师)'],
  Expertise: ['Expertise', '正高级(主任医师)']
}
const genderType = {
  Male: ['Male', '男'],
  Female: ['Female', '女'],
  unknown: ['unknown', '无']
}
const userType = {
  SUPER_ADMIN: ['SUPER_ADMIN', '超级管理员'],
  SALES: ['SALES', '销售'],
  CLINIC_CONTACT: ['CLINIC_CONTACT', '门诊联系人'],
  DENTIST: ['DENTIST', '牙医'],
  DENTIST_LEADER: ['DENTIST_LEADER', '医生团队负责人'],
  OPERATION: ['OPERATION', '运营'],
  PATIENT: ['PATIENT', '病人'],
  LEAD: ['LEAD', '线索'],
  PRODUCER: ['PRODUCER', '生产人员'],
  DESIGNER: ['DESIGNER', '设计师'],
}

const STATUSMAP = function(data:any){
  let enumtype = {};
  switch (data) {
    case 'ClinicType':
      enumtype = ClinicType;
      break;
    case 'levelType':
      enumtype = levelType;
      break
    case 'genderType':
      enumtype = genderType;
      break
    case 'userType':
      enumtype = userType;
      break
    default:
      break;
  }
  
  return createEnum(enumtype)
};
function createEnum(definition:any) {
  const valueMap:any = {};
  const descMap:any = {};
  for (const key of Object.keys(definition)) {
    const [value, desc] = definition[key];
    valueMap[key] = value;
    descMap[value] = desc;
  }
  return {
    ...valueMap,
    getDesc(key: string | number) {
      return (definition[key] && definition[key][1]) || '无';
    },
    getDescFromValue(value: string | number) {
      return descMap[value as keyof typeof descMap] || '无';
    }
  }
}

export default STATUSMAP